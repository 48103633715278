@import "bourbon/bourbon";
@import "neat/neat";

@import "refills/expander";

@import "base/base";
@import "fonts/fonts";

$layout-grid: (
    columns: 3,
    gutter: 2rem,
);

$layout-grid--tablet-up: (
    columns: 12,
    gutter: 2rem,
    media: "(min-width: 768px)",
);

.layout-grid {
    @include grid-container;
}

body {
    margin: 0 0 0 0;
}

header {
    @include grid-media($layout-grid--tablet-up) {
      @include grid-shift(1, $layout-grid--tablet-up);
    }
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.header-row {
    @include grid-container;
}

.title-wrapper {
    background-color: #3a2;
    padding-left: 2rem;
    padding-right: 2rem;
}
.title-container {
    @include grid-container;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

acronym {
    border-bottom: 1px dotted #aaa;
}


.content {
    @include grid-container;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.content-section {
    @include grid-container;
    @include grid-column(3, $layout-grid);
    @include grid-media($layout-grid--tablet-up) {
        @include grid-column(12, $layout-grid--tablet-up);
    }
}

#aboutme .aboutme-description {
    @include grid-column(3, $layout-grid);

    @include grid-media($layout-grid--tablet-up) {
        @include grid-shift(2, $layout-grid--tablet-up);
        @include grid-column(6, $layout-grid--tablet-up);

        h1 {
            display: none;
        }
    }

    margin-left: 0em;
}

#aboutme img {
    display: none;

    @include grid-media($layout-grid--tablet-up) {
        @include grid-column(2, $layout-grid--tablet-up);
        @include grid-shift(2, $layout-grid--tablet-up);
        border: 1rem solid $secondary-background-color;
        border-radius: 0.25rem;
        display: block;
    }

}

#contact {
    @include grid-container;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;

}

#contact-wrapper {
    margin-top: 3rem;
    margin-bottom: 2rem;
    background-color: $secondary-background-color;
}

#profiles {
    @include grid-column(3, $layout-grid);
    @include grid-media($layout-grid--tablet-up) {
      @include grid-column(3, $layout-grid--tablet-up);
      @include grid-shift(3, $layout-grid--tablet-up);
    }
    padding-top: 1rem;
    padding-bottom: 1rem;
}

#contact-details {
    @include grid-column(3, $layout-grid);
    @include grid-media($layout-grid--tablet-up) {
      @include grid-shift(3, $layout-grid--tablet-up);
      @include grid-column(4, $layout-grid--tablet-up);
    }

    padding-top: 1rem;
    padding-bottom: 1rem;
}

#contact-details li.fingerprint {
    font-size: modular-scale(-1);
}

.post-container {
    @include grid-container;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;

    margin-top: 1rem;
    margin-bottom: 1rem;
}

.post-container > * {
    @include grid-column(3, $layout-grid);
    @include grid-media($layout-grid--tablet-up) {
      @include grid-shift(1, $layout-grid--tablet-up);
      @include grid-column(10, $layout-grid--tablet-up);
    }
}

.post-list li {
    font-weight: bold;
}

.publications {
    @include grid-column(3, $layout-grid);
    @include grid-media($layout-grid--tablet-up) {
      @include grid-shift(1, $layout-grid--tablet-up);
      @include grid-column(10, $layout-grid--tablet-up);
    }
    margin-bottom: 1rem;
}

.publications li {
    margin-bottom: 1rem;
}

.publications a.media {
    font-weight: bold;
}

.publications .media {
    font-size: modular-scale(-1);
}

.publications .doi {
    font-size: modular-scale(-1);
}

.publications .title, .properties td.key span.key  {
    font-weight: bold;
    color: #060;
    background-color: #bea;
    display: inline;
    box-shadow: 0.5em 0 0 #bea, -0.5em 0 0 #bea;
    padding: 0.2em 0.0em 0.2em 0.0em;
    margin: -0.2em 0.5em -0.2em 0.0em;
    -webkit-box-decoration-break: clone;
    -ms-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
}

.publications .title-without-link {
    background-color: #eee;
    box-shadow: 0.5em 0 0 #eee, -0.5em 0 0 #eee;
}

.publications .title.link:hover {
    background-color: #087010;
    box-shadow: 0.5em 0 0 #087010, -0.5em 0 0 #087010;
}

.publications .title.link:hover a {
    color: #fff;
}

.publications .title a {
    color: #060;
}

::selection {
    color: white;
    background: #cc3b22;
}

.properties td {
    vertical-align: top;
    border-bottom: 1px solid #bbb;
}

.properties td.key {
    text-align: left;
    padding-left: 1em;
    width: 6em;
}

table.properties {
    @include grid-column(8, $layout-grid);
    border-style: solid;
}
