@include font-face(
	"Source Serif Pro",
	"/assets/fonts/SourceSerifPro/SourceSerifPro-Regular.otf",
        $file-formats: woff
);

@include font-face(
	"Source Serif Pro",
	"/assets/fonts/SourceSerifPro/SourceSerifPro-It.otf",
        $file-formats: woff
) {
	  font-weight: normal;
    font-style: italic;
}

@include font-face(
	"Source Serif Pro",
	"/assets/fonts/SourceSerifPro/SourceSerifPro-Bold.otf",
        $file-formats: woff
) {
	  font-weight: bold;
    font-style: normal;
}

@include font-face(
	"Source Serif Pro",
	"/assets/fonts/SourceSerifPro/SourceSerifPro-BoldIt.otf",
        $file-formats: woff
) {
	  font-weight: bold;
    font-style: italic;
}
