@include font-face(
	"Source Sans Pro",
        "/assets/fonts/SourceSansPro/SourceSansPro-Regular.otf",
        $file-formats: woff
);

@include font-face(
	"Source Sans Pro",
        "/assets/fonts/SourceSansPro/SourceSansPro-It.otf",
        $file-formats: woff
) {
	  font-weight: normal; font-style: italic;
}

@include font-face(
	"Source Sans Pro",
        "/assets/fonts/SourceSansPro/SourceSansPro-Bold.otf",
        $file-formats: woff
) {
	  font-weight: bold;
}

@include font-face(
	"Source Sans Pro",
        "/assets/fonts/SourceSansPro/SourceSansPro-BoldIt.otf",
        $file-formats: woff
) {
    font-weight: bold;
    font-style: italic,
}
