ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}

#content ul {
         list-style-type: square;
         padding-left: 2rem;
         padding-bottom: 1rem;
}

#content ol {
         list-style-type: decimal;
         padding-left: 2rem;
         padding-bottom: 1rem;
}